import React from 'react';
import _ from 'lodash';
import { getCurrentTab, getTabLists, collectMenuTabs } from "../../helper"
import { withSubtheme } from '../../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlobalLayout from "../../GlobalLayout";
import Tabs from '../../tabs';
import MenuListTabs from '../../iconMenuList/menuListIconsTabs';
import SingleProperty from '../../property/singleProperty';
// import ContactModule from '../../contactModule';
// import SimpleContactModule from '../../contactModule/simpleContact';
import PageTitle from '../../pageTitle';
// import Activities from '../../activities';
// import FeedbackProgress from '../../feedbackProgress';
import Container from '@mui/material/Container';
import FloatingContainer from '../../floatingContainer';
import ReportFaultBtn from '../../property/components/reportFaultBtn';
import BookViewingBtn from '../../property/components/bookViewing';
import CreateTenancyBtn from '../../property/components/createTenancy';
import ContactBtn from '../../property/components/contactBtn';
import _properties from '../../../../sample/properties.yaml';
import { MyaccountStoreProvider, PropertyActionStoreProvider, useMyaccountState, usePropertyActionState, useAuthState } from "../../../../services";
import CircularProgress from "../../progress/CircularProgress";
// import Actions from '../../actions';
import PendingActions from '../../pendingActions/propertyDetails';
import RecentActivites from '../../recentActivities/propertyDetails';
// import OnlyActions from '../../pendingActions/propertyDetails';
import ActionsWithLayout from '../../pendingActions/withGlobalLayout';
import NoActions from "../../progress/NoActions";
// import { MYACCOUNT_INBOX } from "../../../../constants/urls";
import FeedbackContent from "../../pages/my-property/common/feedback";
import PaymentsContent from "../../pages/my-property/common/transaction";
// import TaskContent from "../../pages/my-property/common/task";
import FaultIssuesContent from "../../pages/my-property/common/faultsandissues";
import TenancyContent from "../../pages/my-property/common/tenancy";
import DocumentContent from "../../pages/my-property/common/document";
import { canShowNewDocumentBtn } from '../../property/components/newDocument/utils';
import TenancyOverview from "../../pages/my-property/common/tenancyOverview";
import ContactModuleComp from "../../pages/my-property/common/contactModule";
import AppointmentsContent from "../../pages/my-property/common/appointment";
import MyPropertySkeleton from "./myPropertySkeleton"
import defaults from './defaults';

// --------------------------------------------------------------------------
// SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT

const BookViewingBtnWrapComp = (props) => {

    const { services } = usePropertyActionState();
    const { services:authServices } = useAuthState();

    const reloadDataFun = React.useCallback(() => {
        authServices.getPendingActionsCount();
        services.getPendingActions({property_id:props.property.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <BookViewingBtn
            {...props}
            reloadDataFun={reloadDataFun}
        />
    )
}

const CreateTenancyBtnWrapComp = (props) => {

    // const { services } = usePropertyActionState();
    // const { services:authServices } = useAuthState();

    // const reloadDataFun = React.useCallback(() => {
    //     authServices.getPendingActionsCount();
    //     services.getPendingActions({property_id:props.property.crm_id});
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <CreateTenancyBtn
            {...props}
            // reloadDataFun={reloadDataFun}
        />
    )
}

const ReportFaultBtnComp = (props) => {
    const { services } = usePropertyActionState();
    const reloadDataFun = React.useCallback(() => {
        services.getWorksOrders({property_id:props.property.crm_id});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ReportFaultBtn
            {...props}
            reloadDataFun={reloadDataFun}
        />
    )
}

const FloatingContainerComp = ({property, actionParams}) => {
    const { showMakeAnOfferBtn, showBookaViewingBtn } = actionParams
    const params = {
        property: property?.property_detail,
        is_owner: property?.is_owner,
        current_tenancy_id: property?.current_tenancy_id,
        owner_type: property?.owner_type,
        tenant_details: property?.current_tenancy?.tenant_details,
    }

    const canMakeAnOffer = property?.appointments.length;

    return (
        <FloatingContainer>
            <ContactBtn
                {...params}
            />
            {canShowNewDocumentBtn(params) && (
                <ReportFaultBtnComp
                    btnLabel="Report"
                    {...params}
                />
            )}
            {(!!canMakeAnOffer && showMakeAnOfferBtn) && (
                <CreateTenancyBtnWrapComp {...params} />
            )}
            {!!showBookaViewingBtn && (
                <BookViewingBtnWrapComp
                    {...params}
                />
            )}
        </FloatingContainer>
    )
}

const SinglePropertyComp = (props) => {

    let offerText = '';
    if (props.property?.offers && props.property.offers.length) {
        offerText = `${props.property.offers.length} offers received`;
    }

    let property = _properties[0]; // Use default data if the property data is not there

    if (props.property) {
        property = {
            ...props.property,
            offerText: offerText
        }
    }

    return(
        <>
            <SingleProperty
                properties={[property]}
                altClassName={`persona-details`}
            />
        </>
    )
}

const InspectionsComp = (props) => {
    let data = props?.inspections || [];

    if (!data.length)
        return <></>

    return (
        <ActionsWithLayout
            title="Inspections"
            // moreLabel="View all"
            noActionTitle="There are no inspections"
            noActionMessage="At the moment all the inspections are closed..."
            data={data}
            role={`owner`}
        />
    )
}

// END SINGLE COMPONENTS USED IN MOBILE AND DESKTOP LAYOUT
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// TAB COMPONENT CONTENT GRID
const OverviewContent = (props) => {
    const isMobile = props.mobile;
    if (isMobile) {
        return(
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <TabsMobileComp {...props} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ActivityTabsComp
                                theme={props.theme}
                                property={props.property}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InspectionsComp {...props.property} />
                        </Grid>
                        <Grid item xs={12}>
                            <TenancyOverview property={props.property} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SinglePropertyComp
                        theme={props.theme}
                        properties={props.properties}
                        property={props.property?.property_detail}
                    />
                    <FloatingContainerComp
                        actionParams={props.tenant}
                        property={props.property}
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.negotiator_info}
                        moduleTitle="Contact your agent"
                    />
                    <ContactModuleComp
                        theme={props.theme}
                        data={props.property?.manager_negotiator_info}
                        moduleTitle="Property manager"
                    />
                </Grid>
            </Grid>
        )
    }
}

// const RenewalContent = (props) => {
//     const isMobile = props.mobile;
//     if (isMobile) {
//         return(
//             <Grid container spacing={1}>
//                 <Grid item xs={12} lg={8}>
//                     <CurrentTenancy
//                         title="Tenancy Dates"
//                         data={
//                             [
//                                 {
//                                     "id": "asdad",
//                                     "title": "Starts:",
//                                     "value": "21 August 2019"
//                                 },
//                                 {
//                                     "id": "sdfs",
//                                     "title": "Ends:",
//                                     "value": "21 August 2019"
//                                 },
//                                 {
//                                     "id": "cvbs",
//                                     "title": "Mutual Break Clause:",
//                                     "value": " 21 February 2019"
//                                 },
//                             ]
//                         }
//                     />

//                     {/*<MenuListTabsComp
//                         title="Property Overview"
//                         manageValue={props.manageValue}
//                         manageHandleChange={props.manageHandleChange}
//                     />*/}
//                     <FloatingContainerComp
//                         theme={props.theme}
//                         property={props.property?.property_detail}
//                         is_owner={props.property?.is_owner}
//                         current_tenancy_id={props.property?.current_tenancy_id}
//                     />
//                 </Grid>
//             </Grid>
//         )
//     } else {
//         return (
//             <Grid container spacing={1}>
//                 <Grid item xs={12} lg={8}>
//                     <CurrentTenancy
//                         title="Tenancy Dates"
//                         data={
//                             [
//                                 {
//                                     "id": "asdad",
//                                     "title": "Starts:",
//                                     "value": "21 August 2019"
//                                 },
//                                 {
//                                     "id": "sdfs",
//                                     "title": "Ends:",
//                                     "value": "21 August 2019"
//                                 },
//                                 {
//                                     "id": "cvbs",
//                                     "title": "Mutual Break Clause:",
//                                     "value": " 21 February 2019"
//                                 },
//                             ]
//                         }
//                     />
//                     <PendingItems
//                         title="Check in Progress"
//                         variant="withCircle"
//                     />
//                     {/*<MenuListTabsComp
//                         title="Property Overview"
//                         manageValue={props.manageValue}
//                         manageHandleChange={props.manageHandleChange}
//                     />*/}
//                 </Grid>
//                 <Grid item xs={12} lg={4}>
//                     <SinglePropertyComp
//                         theme={props.theme}
//                         properties={props.properties}
//                         property={props.property?.property_detail}
//                     />
//                     <FloatingContainerComp
//                         theme={props.theme}
//                         property={props.property?.property_detail}
//                         is_owner={props.property?.is_owner}
//                         current_tenancy_id={props.property?.current_tenancy_id}
//                     />
//                 </Grid>
//             </Grid>
//         )
//     }
// }

const mainTabData = (props) => {

    let tabItems = {
        "overview": {
            "id": "overview",
            "iconName": "overviewTabIcon",
            "name": "Overview",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <OverviewContent {...props} />,
        },
        "fault-and-issues": {
            "id": "fault-and-issues",
            "name": "Fault & Issues",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <FaultIssuesContent {...props} />,
        },
        "tenancy": {
            "id": "tenancy",
            "iconName": "tenancyIcon",
            "name": "Offers",
            "content": <TenancyContent {...props} role="tenant" />,
        },
        "documents": {
            "id": "documents",
            "iconName": "documentsIcon",
            "name": "Documents",
            "content": <DocumentContent {...props} type="renting" />,
        },
        "transactions": {
            "id": "transactions",
            "iconName": "transactionsIcon",
            "name": "Transactions",
            "content": <PaymentsContent {...props} />,
        },
        // "tasks": {
        //     "iconName": "Tasks",
        //     "name": "Tasks",
        //     "content": <TaskContent {...props} />,
        // },
        "appointments": {
            "id": "appointments",
            "iconName": "appointmentsIcon",
            "name": "Appointments",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <AppointmentsContent {...props} />,
        },
        "feedback": {
            "id": "feedback",
            "iconName": "emailIcon",
            "name": "Feedback",
            "subtitle": "Lorem ipsum dolor sit amet",
            "content": <FeedbackContent {...props} />,
        }
    };

    return tabItems;

}
const MainTabsComp = (props) => {

    const { services: authServices } = useAuthState()
    const property = props?.property
    const pageTitle = property?.property_detail?.display_address;

    // tabItems Can be Array or Objects
    const _tabItems     = ['overview', 'fault-and-issues', 'tenancy', /*'documents',*/ 'transactions', 'appointments', 'feedback'];
    const _defaultItem  = 'overview';
    const tabItems      = props?.tab?.items || _tabItems;
    const defaultItem   = props?.tab?.default || _defaultItem;
    const currentTab    = getCurrentTab(defaultItem, tabItems)
    const availableTabs = mainTabData(props) || {}
    const data          = getTabLists(availableTabs, tabItems)

    const [value, setValue] = React.useState(currentTab);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        const pageRightDrawer = <RightDrawerContent
               props={{property: property, manageValue:value, manageHandleChange:handleChange,tabItems:tabItems, addUrl:true, data:data}}
           />
        authServices.updateState({pageTitle: pageTitle, pageRightDrawer:pageRightDrawer, pageLeftIcon: true});
    }, [pageTitle, value]) // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <Tabs
            data={data}
            manageValue={value}
            tabItems={tabItems}
            addUrl={true}
            altTabClassName={`pd-main`}
        />
    )
}

const ActivityTabsComp = (props) => {
    const dataActivity = [
        {
            "id": "tab1",
            "name": "Pending Actions",
            "content": <PendingActions property={props?.property} />,
        },
        {
            "id": "tab2",
            "name": "Recent Activity",
            "content": <RecentActivites property={props?.property} />,
        }
    ]
    return(
        <Tabs
            data={dataActivity}
            altTabClassName={`pd-second`}
        />
    )
}

const mobileTabData = (props) => {
    return (
        [
            {
                "id": "tab1",
                "name": "Overview",
                "content":
                <>
                    <ContactModuleComp
                        simpleContact={true}
                        data={{...props.property?.negotiator_info, helpTextLink: "Get in touch", helpText: "Need help? "}}
                    />
                    <PropertyActionStoreProvider>
                        <PendingActions
                            property={props?.property}
                            title="Pending Actions"
                            componentLayout={true}
                            // moreLabel="View all"
                            // moreLinkUrl={MYACCOUNT_INBOX}
                            // noActionTitle="There are no pending actions"
                            // noActionMessage="At the moment all the actions are closed..."
                        />
                    </PropertyActionStoreProvider>
                    <InspectionsComp {...props.property} />
                    <TenancyOverview property={props.property} />
                    {/*<MenuListTabsComp
                        title="Property Overview"
                        manageValue={props.manageValue}
                        manageHandleChange={props.manageHandleChange}
                    />*/}
                    <FloatingContainerComp
                        actionParams={props.tenant}
                        property={props.property}
                    />
                </>
            },
            {
                "id": "tab2",
                "name": "Activity",
                "content": <RecentActivites property={props?.property} />,
            },
        ]
    )
}
const TabsMobileComp = (props) => {
    return(
        <Tabs
            data={mobileTabData(props)}
            fullWidthTabs={true}
            altTabClassName={`pd-second`}
        />
    )
}

// const faultTabData = (props) => {
//     return (
//         [
//             {
//                 "id": "tab1",
//                 "name": "Open",
//                 "content": <Activities />
//             },
//             {
//                 "id": "tab2",
//                 "name": "Closed",
//                 "content": <RecentActivites {...props} data={(props?.property?.activity || [])} />
//             },
//         ]
//     )
// }
// const TabsFaultComp = (props) => {
//     return(
//         <Tabs
//             data={faultTabData(props)}
//             fullWidthTabs={true}
//         />
//     )
// }
//
// const transactionsTabData = (props) => {
//     return (
//         [
//             {
//                 "id": "tab1",
//                 "name": "Payments",
//                 "content": <Payments />
//             },
//             {
//                 "id": "tab2",
//                 "name": "Invoices",
//                 "content": <Payments />
//             },
//         ]
//     )
// }
// const TabsTransactionsComp = (props) => {
//     return(
//         <Tabs
//             data={transactionsTabData(props)}
//             fullWidthTabs={true}
//         />
//     )
// }
// END MAIN TABS COMPONENT FOR DESKTOP AND MOBILE
// --------------------------------------------------------------------------


const MyAccountPropertySellerHeaderProperty = withSubtheme((props) => {
    const { className, properties, property } = props;

    return(
        <div className={className}>
            <SinglePropertyComp
                properties={properties}
                property={property?.property_detail}
            />
        </div>
    )
}, 'myPropertyPageSingleHeaderProperty')

const MenuListTabsComp = (props) => {
    const origData = props?.data || mainTabData(props);
    // Create a new array with only the id and name properties, we don't need the rest for the menus
    const newData = collectMenuTabs(origData)

    return(
        <MenuListTabs
            {...props}
            title={props.title && props.title}
            data={newData}
        />
    )
}

const RightDrawerContent = ({props}) => {
    return(
        <MenuListTabsComp
            {...props}
        />
    )
}

const MyAccountPropertyTenantInit = React.memo(({props}) => {

    const { state } = useMyaccountState();
    // const { services: authServices } = useAuthState()
    const { theme } = props;
    const properties = _properties ?? theme.getProp('subthemes.result.sampleData');
    const mobile = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });

    const property = state?.property || {}
    const property_detail = property?.property_detail || {}
    const pageTitle = property_detail?.display_address;

    if (!state.loading && _.isEmpty(property_detail)) {
        return (
            <NoActions
                title="Property not found"
                message="At the moment this property not found"
                icon="propertyNotFound"
            />
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <PageTitle theme={theme}>{pageTitle}</PageTitle>
            </Grid>
            {mobile &&
                <Grid item xs={12}>
                    <MyAccountPropertySellerHeaderProperty
                        theme={theme}
                        properties={properties}
                        property={property}
                    />
                </Grid>
            }
            <Grid item xs={12}>
                <MainTabsComp
                    {...props}
                    mobile={mobile}
                    theme={theme}
                    properties={properties}
                    property={property}
                />
            </Grid>
        </Grid>
    );
})

const WrapComp = React.memo((props) => {

    const {
        theme,
        propertyid,
    } = props;

    const { services, state } = useMyaccountState();

    // fetch the property datas and do the process then
    React.useEffect(() => {
        services.getProperty({id:propertyid})
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loading) {
        return <MyPropertySkeleton tabsCount={7} />;
    }
    return (
        <Container maxWidth="xl">
            <MyAccountPropertyTenantInit props={{...props, theme:theme}} />
        </Container>
    )
})

const MyAccountPropertyTenant = withSubtheme((props) => {
    return (
        <GlobalLayout>
            <MyaccountStoreProvider>
                <PropertyActionStoreProvider>
                    <WrapComp {...props} />
                </PropertyActionStoreProvider>
            </MyaccountStoreProvider>
        </GlobalLayout>
    );
}, 'myPropertyPageTenant', defaults)

export default React.memo(MyAccountPropertyTenant);
